.timezone-picker {
  margin: 20px 0;
  width: 255px;
  z-index: 1;
  border-bottom: 1px solid #888;
}
div.timezone-picker input {
  border-color: transparent;
}

.day h3 {
  text-transform: capitalize;
}