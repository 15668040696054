body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body > [role=tooltip] {
  z-index: 2999;
}

.custom-input-width {
  min-width: 256px !important;
}

.override-flex-direction {
  flex-direction: unset !important;
  display: block !important;
}

.override-flex-direction > div {
  min-width: 256px;
}

.timezone-picker {
  margin: 20px 0;
  width: 255px;
  z-index: 1;
  border-bottom: 1px solid #888;
}
div.timezone-picker input {
  border-color: transparent;
}

.day h3 {
  text-transform: capitalize;
}
.btn {
    background-color: #2196f3;
    box-shadow:0 0 10px rgba(0,0,0,0.2);
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin: 0 20px 20px 0;
    display: inline-block;
    border: none;
    font-size: 16px;
}

.btn[ disabled ] {
    background-color: #999;
}

img {
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 20px;
}

