.btn {
    background-color: #2196f3;
    box-shadow:0 0 10px rgba(0,0,0,0.2);
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin: 0 20px 20px 0;
    display: inline-block;
    border: none;
    font-size: 16px;
}

.btn[ disabled ] {
    background-color: #999;
}

img {
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 20px;
}
